export const APP_CONST = Object.freeze({
  AUTH_ACTIONS: {
    LOGIN: "login",
    REGISTER: "register",
  },
  TOAST_TYPE: {
    ERROR: "error",
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
  },
  SPORTS_ID: {
    CIRCKET: "4",
    SOCCER: "1",
    TENNIS: "2",
  },
  ALL_MARKET_IDS: {
    CRICKET: "CRICKET_IDS",
    SOCCER: "SOCCER_IDS",
    TENNIS: "TENNIS_IDS",
  },
  ODDS_TYPE: {
    BACK: "back",
    LAY: "lay",
  },
  AUTH_MODAL: {
    LOGIN: "login",
    REGISTER: "register",
    MOBILE_NUMBER: "mobileNumber",
    VERIFY_NUMBER: "verifyNumber",
    FORGOTO_PASSWORD: "forgotpassword",
    RESET_PASSWORD: "resetpassword",
  },
  TEST: {},
  PLAYER_BANK_DETAILS_TYPE: {
    Bank: "bank",
    Card: "card",
  },
  ORGANISATION_STATUS: {
    Maintenance: "maintenance",
    Live: "live",
    Suspended: "suspended",
  },
});

export const IS_DEMO_USER_LS_KEY = "jhbfuu7";
